@import "./../node_modules/consent-ui-components/lib/esm/Consent.scss";
@import "./../node_modules/consent-ui-components/lib/esm/ConsentList.scss";

.App{
    padding: 10px;
}
body{
    justify-content: center;
}

